import { registerApplication, start } from "single-spa";

// La aplicacion LAYOUT se carga primero siempre
registerApplication({
  name: "@pixellar/pixellar-frontend-layout",
  app: () => System.import("@pixellar/pixellar-frontend-layout"),
  activeWhen: "/",
});

start();

//window.addEventListener("single-spa:before-first-mount", (evt) => {});

// [HACK] Aqui nos aseguramos que la aplicacion LAYOUT se cargue primero y luego las restantes
window.addEventListener("single-spa:first-mount", (evt) => {
  registerApplication({
    name: "@pixellar/pixellar-frontend-public",
    app: () => System.import("@pixellar/pixellar-frontend-public"),
    activeWhen: [(location) => location.pathname.startsWith("/")],
  });
});